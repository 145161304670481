<template>
  <div>
    <window-header></window-header>
    <nav-form></nav-form>
    <section>
      <article style>
        <img :src="formData.imagen && typeof formData.imagen == 'object'
          ? formData.imagen.imagen
          : require('./../assets/noimage.png')
          " style="
            position: absolute;
            width: 52px;
            height: 52px;
            top: 4px;
            left: 6px;
          " />
        <field name="codigo" widget="input" searchable label="Código" placeholder="Cód." width="67px"
          style="top: 9px; left: 68px" :readonly="mode == 'edit'" inputStyle="font-weight:bold;text-align:center;" />
        <field name="nombre_comercial" max="25" widget="input" label="Nombre Comercial"
          help="Nombre Comercial del Cliente" required placeholder="Nombre..." width="230px"
          style="top: 9px; left: 145px" searchable inputStyle="font-weight:bold;" @input="onInputNombreComercial"
          @focus="onFocusNombreComercial" />
        <!--<field
          name="representante_id"
          dbAdapter="representante"
          widget="m2o"
          label="Representante habitual"
          placeholder="Rep."
          required
          width="200px"
          style="top: 9px; right: 5px"
          searchable
          imgProp="imagen"
        />-->
        <field name="nif_cif" widget="input" label="NIF/CIF" help="NIF/CIF del Cliente" required placeholder="NIF/CIF"
          searchable fontweight="bold" width="90px" style="top: 9px; left: 400px" @change="onChangeNifCif" :class="{
            wrong:
              nif_cif_duplicado ||
              (formData.nif_cif &&
                !$utils.validation.checkVATNumber(formData.nif_cif)),
          }" />
        <img v-if="formData.nacionalidad == 'union_europea'" :src="require('./../assets/ue.png')"
          style="position: absolute; top: 22px; left: 500px" :style="'border:2px solid ' +
            (parseInt(formData.intrastat)
              ? 'green'
              : parseInt(formData.intrastat) == 0
                ? 'red'
                : 'orange')
            " width="20" height="20" />
        <button v-if="formData.nacionalidad == 'union_europea' && (mode == 'new' || mode == 'edit')" class="button"
          style="
            position: absolute;
            top: 22px;
            left: 522px;
            width: 80px;
            line-height: 1;
            font-size: 11px;
          " @click="actualizarIntrastat" v-tooltip="'Validar el nº de IVA en VIES a partir del NIF/CIF introducido.' +
            (formData.fuc_intrastat ? ' Última comprobación: ' + new Date(formData.fuc_intrastat).ddmmyyyy() : '')
            ">
          Comprobar
        </button>
        <r-tabs ref="main-tabs" style="top: 55px; height: 278px" :buttons="[
          'Fiscal',
          'Comercial',
          'Dir.',
          'Contactos',
          'Fact.',
          'Banco',
          'Web',
          'Notas',
          'Img',
          'Saldos',
        ]">
          <div class="tab">
            <field name="nombre_fiscal" widget="input" label="Nombre" help="Razon Social" inline-label
              placeholder="Nombre Fiscal" searchable width="280px" style="top: 10px; right: 300px" required
              @blur="onBlurNombreFiscal" />

            <!-- ELIMINAR 
            <fa-icon
              v-if="formData.nacionalidad == 'union_europea'"
              :icon="estadoIntrastat"
              :spin="estadoIntrastat == 'sync'"
              size="1x"
              style="
                cursor: pointer;
                position: absolute;
                right: 435px;
                top: 43px;
              "
              :style="{
                color:
                  estadoIntrastat == 'sync-alt'
                    ? 'gray'
                    : estadoIntrastat == 'sync'
                    ? 'orange'
                    : estadoIntrastat == 'check'
                    ? 'green'
                    : 'darkred',
              }"
              @click="actualizarIntrastat"
              v-tooltip="
                'Validar el nº de IVA en VIES a partir del NIF/CIF introducido.' +
                (formData.fuc_intrastat
                  ? ' Última comprobación: ' + formData.fuc_intrastat
                  : '')
              "
            />
            
              <field
                
                class="aux"
                name="fuc_intrastat"
                style="top:75px;right:285px;"
                widget="date"
              >
                <i class="fa fa-refresh fa-2x" aria-hidden="true"></i>
                <input type="hidden" name="fuc_intrastat" />
                <span
                  class="rtooltip"
                >Pulsa para validar el nº de IVA en VIES a partir del NIF/CIF introducido</span>
            </field>

            <field
              v-if="formData.intrastat"
              name="numero_intrastat"
              widget="input"
              label="NºIntrastat"
              style="top: 40px; right: 285px"
              width="83px"
              inline-label
              readonly
            />-->
            <field name="direccion_fiscal" widget="input" label="Dirección" help="Dirección Fiscal" inline-label
              placeholder="Dirección fiscal" searchable width="340px" style="top: 40px; right: 238px"
              @change="onChangeCopy('direccion_fiscal', 'direccion_comercial')" />
            <field name="direccion_fiscal1" widget="input" label help="Dirección Fiscal" inline-label searchable
              width="340px" style="top: 70px; right: 238px" @change="
                onChangeCopy('direccion_fiscal1', 'direccion_comercial1')
                " />
            <field name="cp_fiscal" inputalign="center" max="8" widget="input" help="Código Postal del Cliente"
              label="C.P." inline-label placeholder="CP" searchable width="64px" style="top: 100px; right: 513px"
              @change="onChangeCopy('cp_fiscal', 'cp_comercial')" />
            <field name="poblacion_fiscal" widget="input" label inline-label placeholder="Poblacion del Cliente"
              searchable width="275px" style="top: 100px; right: 238px"
              @change="onChangeCopy('poblacion_fiscal', 'poblacion_comercial')" />
            <field name="provincia_fiscal_id" dbAdapter="provincia" widget="m2o" label="Provincia" help="Provincia"
              inline-label placeholder="Provincia" searchable width="160px" style="top: 130px; right: 417px" :fields="[
                'nombre',
                {
                  name: 'pais_id',
                  fields: [
                    'nombre',
                    { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                    'nacionalidad',
                    { name: 'moneda_id', fields: ['nombre'] },
                  ],
                },
              ]" @select="
                onChangeCopy('provincia_fiscal_id', 'provincia_comercial_id')
                " />
            <field name="pais_fiscal_id" dbAdapter="pais" widget="m2o" label="Pais" placeholder="Pais del Cliente"
              help="Pais del Cliente" inline-label searchable width="140px" style="top: 130px; right: 237px"
              @select="onChangeCopy('pais_fiscal_id', 'pais_comercial_id')" :fields="[
                { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                'nacionalidad',
                { name: 'moneda_id', fields: ['nombre'] },
              ]" />
            <field name="representantes_ids" searchable inline-label widget="m2m" dbAdapter="representante"
              label="Representantes" position-label="block" width="310px" style="top: 160px; right: 238px;" :height="60"
              imgProp="thumbnail" searchField="codigo" :readonly="!!formData.todos_representantes" />
            <field name="todos_representantes" widget="checkbox" label="Todos"
              help="Cliente disponible para todos los representantes" inline-label style="top: 203px; right: 555px;"
              @change="$set(formData, 'representantes_ids', null)" />

            <field name="telefono_fiscal" widget="input" label="Teléfono" help="Teléfono del Cliente" inline-label
              placeholder="Teléfono" searchable width="120px" style="top: 40px; right: 63px"
              @change="onChangeCopy('telefono_fiscal', 'telefono_comercial')" />
            <field name="movil_fiscal" widget="input" label="Móvil" help="Teléfono Móvil del Cliente" inline-label
              placeholder="Móvil" searchable width="120px" style="top: 70px; right: 63px"
              @change="onChangeCopy('movil_fiscal', 'movil_comercial')" />
            <field name="email_fiscal" widget="input" help="E-Mail del cliente" label="E-Mail" inline-label
              placeholder="e-mail" searchable width="180px" :class="{
                wrong:
                  formData.email_fiscal &&
                  !$utils.validation.checkEmail(formData.email_fiscal),
              }" style="top: 100px; right: 3px" @change="onChangeCopy('email_fiscal', 'email_comercial')" />
            <field name="web_fiscal" widget="input" help="Web del Cliente" label="Web" inline-label placeholder="Web"
              searchable width="180px" style="top: 130px; right: 3px"
              @change="onChangeCopy('web_fiscal', 'web_comercial')" />
            <field name="adjuntos" :max="6" help="Archivos Adjuntos del Cliente" label="Adjuntos" widget="files"
              style="top: 170px; right: 10px" />
            <field name="activo" widget="toggle" :labels="{ checked: 'Activo', unchecked: 'Inactivo' }" :width="75"
              inline-label style="top: 10px; right: 180px" searchable />
            <field name="tipo_cliente_id" dbAdapter="tipo_cliente" labelProp="descripcion" widget="m2o" searchable
              width="150px" placeholder="Tipo de Cliente" inline-label style="top: 10px; right: 10px" />
          </div>
          <div class="tab">
            <field name="responsable" max="35" widget="input" label="Responsable" placeholder="Responsable"
              help="Responsable de la empresa" inline-label searchable width="280px" style="top: 30px; right: 300px"
              required />
            <field name="direccion_comercial" max="30" widget="input" label="Direccion"
              help="Direccion Comercial del Cliente" inline-label placeholder="Direccion Comercial" searchable
              width="340px" style="top: 65px; right: 240px" />
            <field name="direccion_comercial1" max="30" widget="input" label help="Direccion Comercial" inline-label
              searchable width="340px" style="top: 95px; right: 240px" />
            <field name="cp_comercial" max="8" inputalign="center" help="Código Postal Comercial del Cliente"
              widget="input" label="C.P." inline-label placeholder="CP" searchable width="64px"
              style="top: 125px; right: 516px" />
            <field name="poblacion_comercial" max="25" widget="input" label inline-label
              placeholder="Poblacion Comercial" searchable width="273px" style="top: 125px; right: 243px" />
            <field name="provincia_comercial_id" widget="m2o" help="Provincia del Cliente" dbAdapter="provincia"
              label="Provincia" inline-label placeholder="Provincia Comercial" searchable width="260px" :fields="[
                'nombre',
                {
                  name: 'pais_id',
                  fields: [
                    'nombre',
                    { name: 'impuesto_id', fields: ['nombre', 'valor'] },
                    'nacionalidad',
                    { name: 'moneda_id', fields: ['nombre'] },
                  ],
                },
              ]" style="top: 160px; right: 320px" />
            <field name="pais_comercial_id" widget="m2o" dbAdapter="pais" placeholder="Pais Comercial"
              help="Pais del Cliente" label="Pais" inline-label searchable width="260px"
              style="top: 190px; right: 320px" />

            <field name="telefono_comercial" max="19" widget="input" label="Teléfono"
              help="Teléfono Comercial del Cliente" inline-label placeholder="Teléfono Comercial" searchable
              width="120px" style="top: 30px; right: 50px" />
            <field name="telefono_comercial1" max="19" widget="input" label="Teléfono 1"
              help="Teléfono Comercial del Cliente" inline-label placeholder="Teléfono Comercial" searchable
              width="120px" style="top: 65px; right: 50px" />
            <field name="movil_comercial" max="19" widget="input" help="Móvil Comercial del Cliente" label="Móvil"
              inline-label placeholder="Móvil Comercial" searchable width="120px" style="top: 95px; right: 50px" />
            <field name="fax_comercial" max="19" widget="input" help="Fax Comercial del Cliente" label="Fax"
              inline-label placeholder="Fax Comercial" searchable width="120px" style="top: 125px; right: 50px" />
            <field name="email_comercial" max="40" widget="input" help="E-Mail Comercial del Cliente" label="E-Mail"
              inline-label placeholder="e-mail Comercial" searchable width="180px" style="top: 160px; right: 10px"
              :class="{
                wrong:
                  formData.email_comercial &&
                  !$utils.validation.checkEmail(formData.email_comercial),
              }" />
            <field name="web_comercial" max="40" widget="input" help="Web Comercial del Cliente" label="Web"
              inline-label placeholder="Web Comercial" searchable width="180px" style="top: 190px; right: 10px" />
          </div>
          <div class="tab">
            <field ref="direcciones" name="direcciones" widget="handsontable" style="top: 5px; left: 5px" :columns="[
              {
                name: 'predet',
                header: 'Predet.',
                widget: 'checkbox',
                help: 'Dirección predeterminada para las facturas',
              },
              { name: 'descripcion', header: 'Descripción' },
              { name: 'responsable', header: 'Responsable' },
              { name: 'direccion', header: 'Dirección' },
              { name: 'direccion1', header: '...' },
              { name: 'cp', header: 'CP' },
              { name: 'poblacion', header: 'Población' },
              {
                name: 'provincia_id',
                header: 'Provincia',
                type: 'm2o',
                primary: 'codigo',
                showCode: true,
              },
              {
                name: 'pais_id',
                header: 'País',
                type: 'm2o',
                primary: 'codigo',
                showCode: true,
              },
              {
                name: 'latitud',
                header: 'Latitud',
                type: 'numeric',
                numericFormat: { pattern: '0,0.0000000' },
              },
              {
                name: 'longitud',
                header: 'Longitud',
                type: 'numeric',
                numericFormat: { pattern: '0,0.0000000' },
              },
              { name: 'telefono', header: 'Teléfono' },
              { name: 'telefono1', header: 'Teléfono 1' },
              { name: 'movil', header: 'Móvil' },
              { name: 'movil1', header: 'Móvil 1' },
              { name: 'fax', header: 'Fax' },
              { name: 'email', header: 'Email' },
            ]" :htSettings="htSettingsDirecciones" />
          </div>
          <div class="tab">
            <field name="contactos" widget="handsontable" style="top: 5px; left: 5px" :columns="[
              { name: 'nombre', header: 'Nombre' },
              { name: 'cargo', header: 'Cargo' },
              { name: 'telefono', header: 'Teléfono' },
              { name: 'telefono_movil', header: 'Móvil' },
              { name: 'email', header: 'Email' },
            ]" />
          </div>
          <div class="tab">
            <fieldset style="
                position: relative;
                left: 10px;
                top: 5px;
                width: 633px;
                height: 94px;
              ">
              <legend>Ventas:</legend>
              <field name="descuento_especial" help="% Dto. Especial sobre el total de Compras de este Cliente"
                widget="input" type="number" label="Dto. Especial" placeholder="0.00 %" inputalign="center" dec="2"
                width="55px" style="top: 2px; left: 13px" />
              <field name="financiacion" widget="input" type="number" label="Dto. Financiación"
                help="% Financiación que aparece en la pestaña pie de Ventas" placeholder="0.00 %" inputalign="center"
                dec="2" width="55px" style="top: 2px; left: 92px" />
              <field name="descuento_linea_unidad" widget="input" type="number" label="Dto. L. Unidad"
                help="% Dto. Especial que aparece en las lineas de Ventas cuando se vende a unidad" placeholder="0.00 %"
                inputalign="center" dec="2" width="55px" style="top: 2px; left: 194px" />
              <field name="descuento_linea_peso" widget="input" type="number" label="Dto. L. Peso"
                help="% Dto. Especial que aparece en las lineas de Ventas cuando se vende a peso" placeholder="0.00 %"
                inputalign="center" dec="2" width="55px" style="top: 2px; left: 283px" />
              <field name="merma" widget="input" type="number" help="% Merma asignada a este Cliente en Ventas"
                label="%/mm Merma" placeholder="0.00 %" inputalign="center" dec="2" width="55px"
                style="top: 2px; left: 359px" />
              <field name="margen_metal" widget="input" type="number" label="T% Margen Metal" placeholder="0.00 %"
                inputalign="center" dec="2" width="55px" style="top: 2px; left: 450px" />
              <field name="recargo_equivalencia" help="Si hay Recargo de Equivalencia" widget="checkbox"
                label="R. Equivalencia" inline-label style="top: 45px; left: 10px" />
              <span style="position: absolute; top: 47px; left: 120px; color: #ccc">
                {{ app.config.recargo_equivalencia }}%
              </span>

              <!--<field
                name="re"
                widget="input"
                type="number"
                help="Importe del Recargo de Equivalencia"
                dec="2"
                label="RE"
                inline-label
                placeholder
                width="60px"
                style="top:45px; left:136px;"
              />
              <field
                name="tipo_impuesto"
                widget="select"
                :options="{'IVA':'iva', 'IGIC':'igic', 'TAX':'tax','Otro':'otro'}"
                placeholder="Tipo Impuesto"
                width="60px"
                style="top:45px; left:233px;"
                default="iva"
              />
              <field
                name="iva"
                widget="input"
                type="number"
                dec="2"
                label
                inline-label
                placeholder
                width="55px"
                style="top:45px; left:300px;"
              />-->
              <field name="impuesto_id" widget="m2o" label="Impuesto" inline-label searchable width="150px"
                style="top: 45px; left: 170px" :fields="['nombre', 'valor']" :template-result="(item) =>
                  item.nombre &&
                  item.nombre +
                  ' (' +
                  $utils.misc.formatNumber(item.valor) +
                  '%)'
                  " :template-selection="(item) =>
                    item.nombre &&
                    item.nombre +
                    ' (' +
                    $utils.misc.formatNumber(item.valor) +
                    '%)'
                    " />

              <field name="metal_linea" widget="checkbox" label="Metal en Linea" inline-label
                style="top: 45px; left: 390px" />
              <field name="merma_linea" widget="checkbox" label="Merma por Linea" inline-label
                style="top: 45px; left: 496px" />
            </fieldset>
            <fieldset style="
                position: relative;
                left: 10px;
                top: 10px;
                width: 270px;
                height: 60px;
              ">
              <legend>P.V.P.:</legend>
              <field name="margen" widget="input" type="number" dec="2" label="Margen"
                help="Margen Comercial para el calculo del PVP para el Cliente" inline-label placeholder="0.00 %"
                width="60px" style="top: 6px; left: 9px" />
              <field name="redondeo" widget="input" type="number" dec="3" label="Redondeo"
                help="Redondeo aplicado al PVP para el Cliente" inline-label placeholder="0.000" width="60px"
                style="top: 6px; left: 133px" />
            </fieldset>
            <fieldset style="
                position: relative;
                left: 290px;
                top: -50px;
                width: 206px;
                height: 59px;
              ">
              <legend>Nacionalidad:</legend>
              <field name="nacionalidad" widget="radio" default="nacional" :options="{
                Nacional: 'nacional',
                'Extracom.': 'extracomunitario',
                UE: 'union_europea',
              }" inline-label style="top: 6px; left: 15px" />
            </fieldset>
            <!--<field
              v-show="formData.nacionalidad == 'union_europea'"
              name="intrastat"
              value="false"
              widget="toggle"
              :labels="{ checked: 'Sí', unchecked: 'No' }"
              :width="50"
              label="Intrastat"
              inline-label
              style="top: 110px; left: 530px"
            />
            <button
              v-if="formData.nacionalidad == 'union_europea'"
              class="button"
              style="position: absolute; top: 140px; left: 530px; width: 100px"
              @click="actualizarIntrastat"
              v-tooltip="
                'Validar el nº de IVA en VIES a partir del NIF/CIF introducido.' +
                (formData.fuc_intrastat
                  ? ' Última comprobación: ' +
                    (formData.fuc_intrastat &&
                      new Date(formData.fuc_intrastat).yyyymmdd())
                  : '')
              "
            >
              Comprobar
            </button>-->
            <!--<field
              v-if="
                formData.nacionalidad == 'union_europea' && formData.intrastat
              "
              name="numero_intrastat"
              widget="input"
              label="Nº"
              style="top: 143px; left: 530px"
              width="90px"
              inline-label
            />-->
            <field name="dias_pago" widget="input" label="Dias de Pago" inline-label placeholder="0" inputalign="center"
              width="25px" style="top: 114px; right: 12px" />
            <field name="numero_proveedor" widget="input" label="Nº Proveedor" inline-label inputalign="center"
              width="50px" style="top: 143px; right:12px" />

            <field name="vacaciones_desde_dia" widget="input" label="Vacaciones Desde" inline-label placeholder="0"
              inputalign="center" width="20px" style="top: 175px; left: 12px" />
            <field name="vacaciones_desde_mes" widget="input" inline-label placeholder="0" inputalign="center"
              width="20px" style="top: 175px; left: 130px" />
            <field name="vacaciones_hasta_dia" widget="input" label="Hasta" inline-label placeholder="0"
              inputalign="center" width="20px" style="top: 175px; left: 170px" />
            <field name="vacaciones_hasta_mes" widget="input" inline-label placeholder="0" inputalign="center"
              width="20px" style="top: 175px; left: 227px" />

            <field name="plantilla_pedido_app" label="Plantilla pedidos" labelProp="nombre" inline-label width="200px"
              widget="m2o" style="top: 175px; left: 270px" />

            <field name="forma_pago_id" label="Forma Pago" labelProp="descripcion" inline-label width="200px"
              widget="m2o" dbAdapter="forma_pago" style="top: 205px; left: 13px" />
            <field name="divisa_id" label="Divisa" inline-label width="200px" widget="m2o" dbAdapter="moneda"
              style="top: 205px; left: 290px" />
            <field name="tarifa" label="Tarifa" inline-label width="65px" widget="select" placeholder="Tarifa" :options="{
              '1': '1',
              '2': '2',
              '3': '3',
              '4': '4',
              '5': '5',
              '6': '6',
              '7': '7',
              '8': '8',
              '9': '9',
              Web: '10',
            }" style="top: 205px; left: 538px" />
          </div>
          <div class="tab">
            <field name="bancos" widget="handsontable" style="top: 5px; left: 5px" :columns="[
              { name: 'predet', header: 'Predet.', widget: 'checkbox' },
              {
                name: 'cuenta',
                header: 'Cuenta',
                renderer: rendererCuenta,
                allowInvalid: true,
              },
              { name: 'titular', header: 'Titular' },
              {
                name: 'tipo_cuenta',
                header: 'Tipo',
                widget: 'select',
                options: ['EU', 'INT'],
              },
              { name: 'swift', header: 'SWIFT' },
            ]" :htSettings="htSettingsBancos" />
          </div>
          <div class="tab">
            <field name="activo_web" widget="checkbox" label="Activo en la web" inline-label
              style="top: 15px; right: 10px"
              @change="formData.activo_web || ((formData.login = null), (formData.password = null))" />
            <field name="login" widget="char" label="Login" help="Login" inline-label placeholder="Login" searchable
              width="200px" style="top:47px; right:10px;" />
            <field name="password" widget="char" label="Password" type="password" inline-label placeholder="•••••••"
              searchable width="154px" style="top:87px; right:10px;"
              :class="{ 'placeholder-black': itemId && lastFormData.login && formData.login }" />
          </div>
          <div class="tab">
            <field name="notas" widget="text" placeholder="Escriba aquí las notas..." width="645px" height="100px"
              style="top: 5px; left: 5px" />
            <field name="comentarios" widget="handsontable" :height="111" :minRows="4" style="top: 115px; left: 5px"
              :columns="[
                { name: 'fecha', header: 'Fecha', type: 'date2' },
                { name: 'descripcion', header: 'Descripción' },
                { name: 'activo', header: 'Activo', type: 'checkbox' },
              ]" />
          </div>
          <div class="tab">
            <field name="imagen" gallery="galeria_clientes" widget="m2o_img"
              style="width: 190px; height: 190px; top: 15px; left: 20px" dbAdapter="imagen_cliente" />
            <field name="imagen1" gallery="galeria_clientes" widget="m2o_img"
              style="width: 85px; height: 85px; top: 10px; left: 328px" dbAdapter="imagen_cliente" />
            <field name="imagen2" gallery="galeria_clientes" widget="m2o_img"
              style="width: 85px; height: 85px; top: 10px; left: 470px" dbAdapter="imagen_cliente" />
            <field name="imagen3" gallery="galeria_clientes" widget="m2o_img"
              style="width: 85px; height: 85px; top: 125px; left: 328px" dbAdapter="imagen_cliente" />
            <field name="imagen4" gallery="galeria_clientes" widget="m2o_img"
              style="width: 85px; height: 85px; top: 125px; left: 470px" dbAdapter="imagen_cliente" />
          </div>
          <div class="tab">
            <field name="movimientos" widget="handsontable" style="top: 5px; left: 5px" :height="185" :minRows="7"
              :readonly="true" :columns="[
                {
                  name: 'tipo',
                  header: 'Tipo',
                  type: 'select',
                  options: [
                    {
                      venta: 'Venta',
                      cobro_efectivo: 'Cobro efectivo',
                      cobro_banco: 'Cobro banco',
                      impago: 'Impago',
                      abono: 'Abono',
                    },
                  ],
                },
                { name: 'estado', header: 'Estado' },
                { name: 'fecha', header: 'Fecha', type: 'date2' },
                { name: 'concepto', header: 'Concepto' },
              ]" />
            <span style="position: absolute; bottom: 5px; left: 5px">
              <div class="n_mov" style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  padding: 0px 5px;
                  min-width: 3em;
                  text-align: center;
                  user-select: none;
                "></div>
            </span>
            <span style="position: absolute; bottom: 5px; left: 400px">
              <div class="eur_restante" style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  font-size: 1.5em;
                  width: 6em;
                  text-align: center;
                "></div>
            </span>
            <span style="position: absolute; bottom: 5px; left: 530px">
              <div class="gr_restante" style="
                  border: 1px #aaa solid;
                  height: 1.5em;
                  font-size: 1.5em;
                  width: 6em;
                  text-align: center;
                "></div>
            </span>
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import CliProvRepMixin from "./../components/CliProvRepMixin.js";
import DirMixin from "./../components/DirMixin.js";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin, CliProvRepMixin, DirMixin],
  data: function () {
    var self = this;
    return {
      title: "Clientes",
      dbAdapter: "cliente",
      primary: "codigo",
      defaultDataSearch: {
        activo: 1
      },
      defaultData: {
        activo: 1,
        descuento_especial: 0.0,
        financiacion: 0.0,
        descuento_linea_unidad: 0.0,
        descuento_linea_peso: 0.0,
        merma: 0.0,
        margen_metal: 0.0,
        margen: self.app.config.margen_cliente_defecto,
        redondeo: 0.0,
        nacionalidad: "nacional",
        /*tipo_impuesto: "iva",*/
        tarifa: self.app.config.tarifa_defecto,
        tipo_cliente_id: self.app.config.tipo_cliente_defecto,
        forma_pago_id: self.app.config.forma_pago_clientes_defecto,
        divisa_id: self.app.config.moneda_defecto,
      },
      sequence: {
        name: function () {
          return self.formData.tipo_cliente_id
            ? "cliente_" + self.formData.tipo_cliente_id.codigo
            : null;
        },
        params: ["tipo_cliente_id.id"],
      },
      copyNombreFiscal: true,
      estadoIntrastat: "sync-alt",
      nif_cif_duplicado: false,
    };
  },
  mounted: function () {
    //var $ = window.$;
  },
  methods: {
    actualizarIntrastat() {
      var self = this;
      if (self.mode != "new" && self.mode != "edit") return;
      if (!self.formData.nif_cif) {
        self.app.toast("Introduce algún CIF/NIF", "info");
        return;
      }
      self.estadoIntrastat = "sync";
      window.DB.action("cliente", "comprobarIntrastat", {
        data: {
          nif_cif: self.formData.nif_cif,
        },
      })
        .then(function (res) {
          self.formData.fuc_intrastat = new Date().toISOString().split("T")[0];
          if (res.data) {
            self.$set(self.formData, "intrastat", 1);
            self.estadoIntrastat = "check";
            /*self.formData.numero_intrastat = res.data;*/
          } else {
            self.$set(self.formData, "intrastat", 0);
            self.estadoIntrastat = "exclamation-triangle";
            /*self.formData.numero_intrastat = null;*/
          }
        })
        .catch(function (e) {
          self.estadoIntrastat = "sync-alt";
          self.app.toast(e);
        });
    },
  },
};
</script>
<style>
[name="fuc_intrastat"] {
  cursor: pointer;
  color: darkred;
}
</style>
